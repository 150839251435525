/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    code: "code",
    h2: "h2",
    em: "em",
    br: "br",
    pre: "pre",
    h3: "h3"
  }, _provideComponents(), props.components), {Aside, CodePen, Tweet} = _components;
  if (!Aside) _missingMdxReference("Aside", true);
  if (!CodePen) _missingMdxReference("CodePen", true);
  if (!Tweet) _missingMdxReference("Tweet", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Underlines are hard.\nComplications quickly arise if you want to do anything fancier than the good ol’ CSS ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/CSS/text-decoration"
  }, React.createElement(_components.code, null, "text-decoration: underline")), ".\nThere are ", React.createElement(_components.a, {
    href: "https://css-tricks.com/styling-underlines-web/"
  }, "a lot of different techniques"), ". Unfortunately, they nearly always come with significant drawbacks."), "\n", React.createElement(_components.p, null, "I ran into some of these drawbacks when I wanted to “borrow” the styling from the links in a ", React.createElement(_components.a, {
    href: "https://www.cassie.codes/posts/creating-my-logo-animation/"
  }, "Cassie Evans blogpost"), "."), "\n", React.createElement(_components.p, null, "The links there have this awesome effect when you hover over them: The underline retreats and gets replaced by a new one, leaving a bit of space between the two while the transition happens."), "\n", React.createElement(_components.p, null, "The issue I ran into: Links on my blog often wrap to a different line and that means part of the link would not be underlined 😢."), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "Not only the links on ", React.createElement(_components.a, {
    href: "https://www.cassie.codes/"
  }, "Cassie’s website"), " are cool. The entire website is, from code to content.")), "\n", React.createElement(_components.h2, {
    id: "goal"
  }, "Goal"), "\n", React.createElement(_components.p, null, "A ", React.createElement("span", {
    style: {
      color: "rgb(176, 251, 188)"
    }
  }, "colored"), " underline beneath links that has a hover effect where the line retreats and is replaced by a ", React.createElement("span", {
    style: {
      color: "#feb2b2"
    }
  }, "differently colored"), " line.\nThe lines should not touch during this animation, leaving some space between them."), "\n", React.createElement(_components.p, null, "Links that wrap onto new lines should have the underline beneath all lines."), "\n", React.createElement(_components.h2, {
    id: "use-the-background"
  }, "Use the background"), "\n", React.createElement(_components.p, null, "There are many different ways to underline a piece of text.\nThe method I ended up using that met all of the requirements was: Using the ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/CSS/background-image"
  }, React.createElement(_components.code, null, "background-image")), " CSS property."), "\n", React.createElement(_components.p, null, "A ", React.createElement(_components.code, null, "background-image"), " can be a solid color by defining it as a ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/CSS/linear-gradient"
  }, "linear-gradient"), " that ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/CSS/transition"
  }, "transitions"), " from one color to the same color."), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "Why am I using ", React.createElement(_components.code, null, "background-image"), " and not ", React.createElement(_components.code, null, "background-color"), " if I intend to use a solid color?"), React.createElement(_components.p, null, "Because many properties to manipulate the background only work if ", React.createElement(_components.code, null, "background-image"), " is used."), React.createElement(_components.p, null, "- ", React.createElement(_components.em, null, "insert CSS is hard meme here"), " -")), "\n", React.createElement(_components.p, null, "The size of the background is limited in height and takes up the full width of the anchor element by setting the ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/CSS/background-size"
  }, React.createElement(_components.code, null, "background-size")), " to ", React.createElement(_components.code, null, "2px"), " and ", React.createElement(_components.code, null, "100%"), " respectively."), "\n", React.createElement(_components.p, null, "This still ends up covering the entire background, because now it repeats over and over until it covers the entire background. So I stopped it from being naughty by setting ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/de/docs/Web/CSS/background-repeat"
  }, React.createElement(_components.code, null, "background-repeat")), " to ", React.createElement(_components.code, null, "no-repeat"), "."), "\n", React.createElement(_components.p, null, "The line is at the top of the anchor element! Positioning it with ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/CSS/background-position"
  }, React.createElement(_components.code, null, "background-position")), " set to ", React.createElement(_components.code, null, "0 100%"), " places it at the left edge, and 100% from the top edge of the anchor element.", React.createElement(_components.br), "\n", "In other words, at the bottom… It’s at the bottom now."), "\n", React.createElement(CodePen, {
    codePenId: "qBbQdpQ",
    tabs: ["css", "result"]
  }), "\n", React.createElement(_components.h2, {
    id: "two-backgrounds"
  }, "Two backgrounds"), "\n", React.createElement(_components.p, null, "To use and manipulate multiple background images, set multiple values for the ", React.createElement(_components.code, null, "background-*"), " properties, seperated by a comma."), "\n", React.createElement(_components.p, null, "The first entry in a comma seperated list is on top, with each following entry a layer behind it."), "\n", React.createElement(_components.p, null, "The background of the following anchor element will be entirely black (", React.createElement(_components.code, null, "#000000"), "). The white (", React.createElement(_components.code, null, "#FFFFFF"), ") background is there, but it’s not visible because it’s covered by the black one."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-css"
  }, "a {\n  background-image: linear-gradient(#000000, #000000), linear-gradient(#ffffff, #ffffff);\n}\n")), "\n", React.createElement(_components.p, null, "In the example below, two backgrounds are set. Both at the bottom, making one overlap the other."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-css",
    hl: "4-7"
  }, "a {\n  color: #dfe5f3;\n  text-decoration: none;\n  background-image: linear-gradient(rgb(176, 251, 188), rgb(176, 251, 188)),\n    linear-gradient(#feb2b2, #feb2b2);\n  background-size: 100% 2px, 100% 2px;\n  background-position: 100% 100%, 0 100%;\n  background-repeat: no-repeat, no-repeat;\n}\n")), "\n", React.createElement(CodePen, {
    codePenId: "vYLQNRg",
    tabs: ["css", "result"]
  }), "\n", React.createElement(_components.h3, {
    id: "transitioning-the-background-size"
  }, "Transitioning the ", React.createElement(_components.code, null, "background-size")), "\n", React.createElement(_components.p, null, "Notice how the ", React.createElement(_components.code, null, "background-position"), " is different, while it makes no visible difference?\nOne is anchored to the left side, the other is anchored to the right side."), "\n", React.createElement(_components.p, null, "Next, I’ll be transitioning between one background taking up the full width normally and no width on hover while the second background does the opposite."), "\n", React.createElement(_components.p, null, "That anchoring will affect which point each background moves from/towards."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-css",
    hl: "6,7,9,12-15"
  }, "a {\n  color: #dfe5f3;\n  text-decoration: none;\n  background-image: linear-gradient(rgb(176, 251, 188), rgb(176, 251, 188)),\n    linear-gradient(#feb2b2, #feb2b2);\n  background-size: 100% 2px, 0 2px;\n  background-position: 100% 100%, 0 100%;\n  background-repeat: no-repeat;\n  transition: background-size 2s linear;\n}\n\na:hover {\n  background-size: 0 2px, 100% 2px;\n}\n")), "\n", React.createElement(CodePen, {
    codePenId: "NWxEGmL",
    tabs: ["css", "result"]
  }), "\n", React.createElement(_components.h2, {
    id: "three-backgrounds"
  }, "Three backgrounds"), "\n", React.createElement(_components.p, null, "This almost satisfies the ", React.createElement(_components.a, {
    href: "#goal"
  }, "goals"), ". The only thing missing is the space between the two lines."), "\n", React.createElement(_components.p, null, "That space can be faked by moving a block with the same color as the background.\nWhat is that block? You guessed it: another background."), "\n", React.createElement(_components.p, null, "What is better than 2 background? Three backgrounds!"), "\n", React.createElement(_components.p, null, "Three backgrounds .. ", React.createElement(_components.a, {
    href: "https://youtu.be/2AoxCkySv34"
  }, React.createElement(_components.em, null, "ah ah ah")), " 🦇"), "\n", React.createElement(_components.p, null, "I’ll place this background on top of the other two by listing it first in the comma seperated value for ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/CSS/background-image"
  }, React.createElement(_components.code, null, "background-image")), "."), "\n", React.createElement(Aside, {
    variant: "danger"
  }, React.createElement(_components.p, null, "Don’t forget!", React.createElement(_components.br), "\n", "The first value for other ", React.createElement(_components.code, null, "background-*"), " properties now also points to this newly added ", React.createElement(_components.code, null, "background-image"), ".")), "\n", React.createElement(_components.p, null, "The width and height are set by ", React.createElement(_components.code, null, "background-size"), ". While the height is set to the same size as the other backgrounds (", React.createElement(_components.code, null, "2px"), " in this example). This time, the width is set to be a fairly small ", React.createElement(_components.code, null, "20px"), "."), "\n", React.createElement(_components.h3, {
    id: "transitioning-the-background-position"
  }, "Transitioning the ", React.createElement(_components.code, null, "background-position")), "\n", React.createElement(_components.p, null, "To make the background-colored block invisible before hovering over the anchor element, the background is given a negative ", React.createElement(_components.code, null, "background-position"), " that places it to the left of the element, and thus, completely off the screen."), "\n", React.createElement(_components.p, null, "After hovering on the anchor, the block should move to the opposite side of the underline until it is completely offscreen again."), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/CSS/calc"
  }, React.createElement(_components.code, null, "calc()")), " function is used to calculate both of these positions."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-css",
    hl: "4,8,9,11,15,16"
  }, "a {\n  color: #dfe5f3;\n  text-decoration: none;\n  background-image: linear-gradient(#222b40, #222b40), linear-gradient(\n      rgb(176, 251, 188),\n      rgb(176, 251, 188)\n    ), linear-gradient(#feb2b2, #feb2b2);\n  background-size: 20px 2px, 100% 2px, 0 2px;\n  background-position: calc(20px * -1) 100%, 100% 100%, 0 100%;\n  background-repeat: no-repeat;\n  transition: background-size 2s linear, background-position 2s linear;\n}\n\na:hover {\n  background-size: 20px 2px, 0 2px, 100% 2px;\n  background-position: calc(100% + 20px) 100%, 100% 100%, 0 100%;\n}\n")), "\n", React.createElement(CodePen, {
    codePenId: "JjGeGar",
    tabs: ["css", "result"]
  }), "\n", React.createElement(_components.h2, {
    id: "tada-"
  }, "Tada 🎉"), "\n", React.createElement(Aside, {
    variant: "success"
  }, React.createElement(_components.p, null, "The anchor tag works and fits all the ", React.createElement(_components.a, {
    href: "#goal"
  }, "goals"), "!")), "\n", React.createElement(_components.p, null, "A big thank you to ", React.createElement(_components.a, {
    href: "https://twitter.com/jh3yy"
  }, "Jhey “Jh3y” Tompkins"), "!"), "\n", React.createElement(_components.p, null, "He is a magician with all things CSS/animation and I’m really glad I reached out to him."), "\n", React.createElement(_components.p, null, "I asked him a question when I was trying to figure this out. He not only answered it and taught me about the ", React.createElement(_components.code, null, "background-position"), " technique mentioned above.\nHe took it as a fun challenge and made an awesome proof of concept!"), "\n", React.createElement(Tweet, {
    tweetLink: "jh3yy/status/1283155273113382914",
    theme: "dark"
  }), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "I got to know Jhey in the ", React.createElement(_components.a, {
    href: "https://discord.gg/partycorgi"
  }, "party corgi discord"), ".\nIt’s an awesome place that is filled with talented people, come hang out!")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
